const CONSTANTS = {
  /*localHost api url*/
    // API_URL: 'http://localhost:9000',
  /*test api url*/
  // API_URL: 'https://test.titandef.com',

  /*dev api uri*/

  API_URL: 'https://test.titandef.com',

  /*dev api uri*/

 // API_URL: 'https://ui.titandef.com',

// API_URL: 'https://ui.technative.in',


  /*prod api uri*/
     // API_URL: 'https://prod.titandef.com',

  /*ui api uri*/
  //  API_URL: 'https://app.titandef.com',

  /*indian server api uri*/
  // API_URL: 'http://test.titandef.technative.in:9000',

  SITEKEYFORCAPTCHA: '6Lcqb-gUAAAAAOGVHdw0MutHwAlPuLfo8UCZkTe3',
};

export default CONSTANTS;
